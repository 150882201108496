import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/builds/Mondaic/infrastructure/website/src/templates/mdx_template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Impressum`}</h1>
    <h3>{`Mondaic AG`}</h3>
    <p>{`c/o Impact Hub Zurich`}<br />{`
Sihlquai 131`}<br />{`
8005 Zurich`}<br />{`
Switzerland`}</p>
    <p>{`Phone: +41 76 747 0715`}<br />{`
Email: `}<a parentName="p" {...{
        "href": "mailto:info@mondaic.com"
      }}>{`info@mondaic.com`}</a></p>
    <p>{`Handelsregisternummer / Commercial Register Number: CHE-331.665.438`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      